
/*
    Loosely based on
    https://github.com/panter/vue-i18next/blob/master/src/directive.js, but with
    fewer features, and modifies `el.innerHTML` so HTML content can be used in
    copydeck nodes.
*/

function assert (vnode) {
    const { context: vm } = vnode;

    if (!vm.$t) {
        console.warn('No $t() method found in the Vue instance');
        return false;
    }

    return true;
}

function t (el, binding, vnode) {
    const { value: key } = binding;

    if (!key) {
        console.warn('v-t: "key" is required');
        return;
    }

    const { context: vm } = vnode;

    el.innerHTML = vm.$t(key);
}

export function bind (el, binding, vnode) {
    if (!assert(vnode)) {
        return;
    }

    t(el, binding, vnode);
}

export function update (el, binding, vnode, oldVNode) {
    t(el, binding, vnode);
}

export default {
    bind,
    update,
};
